<template>
  <main>
    <div class=" shadow-md border rounded-md bg-white w-full flex p-5">
      <div class="w-full">
        <div
          style="max-height: 12rem;"
          class="flex flex-wrap gap-6 w-full  overflow-y-auto custom-scrollbar"
          v-if="selectedStores && selectedStores.length"
        >
          <p
            class="text-xs uppercase text-gray-600 font-semibold  p-px bg-gray-200 px-1 rounded-md"
            v-for="stores in selectedStores"
            :key="stores.name"
          >
            {{ stores.name }}
          </p>
        </div>
        <p
          v-else
          class="text-xs uppercase text-gray-600 font-semibold text-center"
        >
          No Store Selected
        </p>
        <div class="flex justify-between relative w-full  ">
          <div class="mt-8">
            <p
              v-if="currentName !== 'Custom'"
              class="text-xs uppercase text-gray-500 mt-5 font-semibold"
            >
              {{ currentName }}'s Payment
            </p>
            <p
              v-else
              class="text-xs uppercase text-gray-500 mt-5 font-semibold"
            >
              {{ currentName }} filtered Payments from
              <span class="text-gray-800">
                {{ moment(date.startDate).format("DD-MMMM-yyyy") }}</span
              >
              to
              <span class="text-gray-800">
                {{ moment(date.endDate).format("DD-MMMM-yyyy") }}</span
              >
            </p>
            <h2 class=" text-2xl font-semibold">
              {{ sum | formatPrice }}
            </h2>
          </div>
          <div class="flex justify-end items-end flex-col ">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import moment from "moment";
export default {
  props: ["selectedStores", "currentName", "date", "sum"],
  data() {
    return {
      moment,
    };
  },
};
</script>

<style></style>
