<template>
  <div>
    <trac-loading class="pt-64" v-if="loading" />
    <div v-else>
      <h1 class="mt-5 font-semibold text-lg">Payments</h1>
      <div class="flex justify-between">
        <div class="mt-8  w-full flex  flex-col   max-w-4xl ">
          <PaymentInfo
            :currentName="currentName"
            :selectedStores="selectedStores"
            :date="date"
            :sum="totalPayment"
          >
          </PaymentInfo>
          <div class="flex items-center justify-between w-full mt-10 ">
            <PaymentSearch v-model="search" class="w-2/5 " />
            <div class="flex items-center justify-between gap-5 z-20  ">
              <DateFilter
                v-model="date"
                @updateName="update"
                :currentName="currentName"
              />
              <Stores
                @toggleCheck="toggleCheck"
                :stores="storeData"
                :checkStores="checkStores"
                v-model="selectedStores"
              />

              <StatusFilter @currentStatus="selectedStatus = $event" />
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-between">
          <div
            class="mt-8"
            v-if="
              getUserStatus &&
                permissionsModules[2].parent.permissionsForUser
                  .viewAndManagePayouts
            "
          >
            <ViewPayout />
          </div>
          <div class=" justify-end items-end flex">
            <DownloadCsv :selectedStores="selectedStores" :date="date" />
          </div>
        </div>
      </div>

      <PaymentTable
        class="mt-12 z-50"
        :tableData="paginatedPayments"
        :length="formatPayments.length"
        @getMoreData="fetchData"
      />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import PaymentInfo from "./PaymentInfo.vue";
import PaymentSearch from "./PaymentSearch.vue";
import DateFilter from "./DateFilter.vue";
import Stores from "./SelectedStores.vue";
import PaymentTable from "./PaymentTable.vue";
import DownloadCsv from "./DownloadCsv";
import { GET_LOCAL_DB_DATA } from "../../browser-db-config/localStorage";
import { permissionsModules } from "./../settings/permission";
import ViewPayout from "./ViewPayout.vue";
import StatusFilter from "./StatusFilter.vue";

export default {
  data() {
    return {
      permissionsModules,
      moment,
      storeData: [],
      selectedStores: [],
      selectedStatus: [],
      currentName: "Today",
      payments: [],
      pageNumber: 1,
      currentlyShowingPayments: [],
      check: true,
      loading: false,
      search: "",
      date: {
        startDate: "",
        endDate: "",
      },
    };
  },

  components: {
    PaymentInfo,
    PaymentSearch,
    DateFilter,
    Stores,
    PaymentTable,
    DownloadCsv,
    ViewPayout,
    StatusFilter,
  },

  watch: {
    async date() {
      await this.getPayments();
    },
  },

  computed: {
    getUserStatus() {
      let usersStatus = GET_LOCAL_DB_DATA("traction-app-user-data");
      if (usersStatus.user.role === "admin") {
        return true;
      } else {
        return false;
      }
    },

    checkStores() {
      return this.selectedStores.length === this.storeData.length;
    },

    formatPayments() {
      let payments = this.payments || [];

      let formattedStores = this.selectedStores.map((stores) => stores.id);

      if (this.selectedStores.length) {
        payments = payments.filter((payment) =>
          formattedStores.includes(payment.store_id.toLowerCase())
        );
      } else {
        payments = [];
      }

      if (this.selectedStatus.length) {
        payments = payments.filter((payment) =>
          this.selectedStatus.includes(payment.payment_status.toLowerCase())
        );
      }

      if (this.search !== "") {
        payments = payments.filter(
          (payment) =>
            payment.payment_log_details.bank_payment_details.account_name
              .toLowerCase()
              .includes(this.search.trim().toLowerCase()) ||
            payment.amount_paid
              .toString()
              .includes(this.search.trim().toLowerCase()) ||
            payment.payment_reference
              .toString()
              .includes(this.search.trim().toLowerCase())
        );
      }
      return payments;
    },

    totalPayment() {
      return this.formatPayments.reduce((a, b) => a + b.amount_paid, 0);
    },

    paginatedPayments() {
      return this.formatPayments.slice(0, 30 * this.pageNumber);
    },
  },

  methods: {
    fetchData() {
      this.pageNumber += 1;
    },

    toggleCheck(value) {
      if (value) {
        this.selectedStores = this.storeData;
      } else {
        this.selectedStores = [];
      }
      this.$forceUpdate();
    },

    update(value) {
      this.currentName = value;
    },

    async fetchAllStores() {
      await this.$store.dispatch("FETCH_ALL_STORES");
      let res = this.$store.getters["GET_ALL_STORES"];
      let formattedStores = res.data.map((store) => {
        return {
          name: store.name,
          id: store._id,
        };
      });
      this.storeData = formattedStores;
      this.selectedStores = formattedStores;
    },

    async getPayments() {
      this.loading = true;
      let res = await this.$store.dispatch("FETCH_ALL_PAYMENTS", this.date);
      if (res.status) {
        this.payments = res.data.items.list_of_payments;
        this.loading = false;
      }
      return res;
    },
  },

  async created() {
    await this.fetchAllStores();
    this.date.startDate = moment().format("YYYY-MM-DD");
    this.date.endDate = moment().format("YYYY-MM-DD");
    await this.getPayments();
  },
};
</script>
