<template>
  <main>
    <div v-click-outside="close" class="flex relative">
      <div
        @click="toggleStore = !toggleStore"
        class="group  border py-3 flex items-center px-5 cursor-pointer hover:border-blue-700  hover:text-blue-700 border-primaryBlue text-xs rounded-md text-primaryBlue font-medium"
      >
        Select Stores
        <div>
          <svg
            class="w-4 h-4  ml-4 fill-current  group-hover:text-blue-700"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.24106 7.7459C4.53326 7.44784 4.99051 7.42074 5.31272 7.66461L5.40503 7.7459L12 14.4734L18.595 7.7459C18.8872 7.44784 19.3444 7.42074 19.6666 7.66461L19.7589 7.7459C20.0511 8.04396 20.0777 8.51037 19.8386 8.83904L19.7589 8.93321L12.582 16.2541C12.2898 16.5522 11.8325 16.5793 11.5103 16.3354L11.418 16.2541L4.24106 8.93321C3.91965 8.60534 3.91965 8.07376 4.24106 7.7459Z"
            />
          </svg>
        </div>
      </div>
      <div
        v-show="toggleStore"
        style="top: 3.5rem; max-height: 20rem"
        class="w-96  text-sm absolute custom-scrollbar right-0 h-auto overflow-y-auto   bg-white rounded-md shadow-md  border border-gray-200  pb-2  "
      >
        <div class="px-4 sticky top-0  pt-5 bg-white">
          <input
            type="text"
            v-model="search"
            class="w-full  py-2   rounded-md leading-7 text-sm border border-gray-300 px-3  bg-white  input-focus "
            placeholder="Search Store Name"
            autofocus
          />
        </div>
        <div
          v-if="filteredStores && filteredStores.length"
          class="flex flex-col mt-4   "
        >
          <div
            class="flex items-center px-4 py-2 group  group hover:bg-gray-200 "
          >
            <input
              type="checkbox"
              class="w-4 h-4 cursor-pointer border group-hover:border-primaryBlue"
              @change="getChange"
              :checked="checkStores"
            />
            <p
              class="text-sm  text-gray-700 text-opacity-75  uppercase cursor-pointer  group-hover:text-primaryBlue   ml-2 leading-6 font-medium"
            >
              All
            </p>
          </div>

          <div v-for="store in filteredStores" :key="store.id">
            <div
              class="flex items-center px-4 py-2 cursor-pointer group  group hover:bg-gray-200 "
            >
              <input
                type="checkbox"
                class="w-4 h-4 cursor-pointer border group-hover:border-primaryBlue"
                v-model="computedFilterBy"
                :value="store"
              />
              <p
                :class="{ 'text-primaryBlue': toggleClass(store) }"
                class="text-sm  text-gray-700 text-opacity-75  uppercase cursor-pointer  group-hover:text-primaryBlue   ml-2 leading-6 font-medium"
              >
                {{ store.name }}
              </p>
            </div>
          </div>
        </div>
        <div v-else class=" pt-4 pb-2 text-sm  text-center">
          <p>No Matched Data</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import ClickOutside from "vue-click-outside";

export default {
  props: ["stores", "value", "checkStores"],
  data() {
    return {
      toggleStore: false,
      search: "",
      allCheck: true,
    };
  },

  mounted() {
    this.$emit("toggleCheck", this.allCheck);
  },

  directives: {
    ClickOutside,
  },

  computed: {
    computedFilterBy: {
      get() {
        return this.value;
      },
      set(store) {
        this.$emit("input", store);
      },
    },
    filteredStores() {
      return this.stores.filter((store) =>
        store.name.toLowerCase().includes(this.search.trim().toLowerCase())
      );
    },
  },

  methods: {
    getChange(event) {
      this.allCheck = event.target.checked;
      this.$emit("toggleCheck", this.allCheck);
    },
    close() {
      this.toggleStore = false;
    },
    toggleClass(store) {
      let copy = [...this.value].map((val) => val.name);
      if (copy.includes(store.name)) {
        return true;
      }
    },
  },
};
</script>

<style>
/* width */
</style>
