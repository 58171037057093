<template>
  <div>
    <div class=" flex w-full">
      <div class="flex flex-col w-full">
        <div class="w-full">
          <div
            style="max-height:60vh"
            class="border rounded-md border-gray-200 w-full shadow-md custom-scrollbar overflow-y-auto "
            @scroll="checkScroll($event)"
          >
            <table class="text-xs w-full">
              <thead
                class="  bg-accentLight border-b border-gray-300 py-5 sticky top-0 "
              >
                <tr>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase "
                  >
                    Payment reference
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Payment Method
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Terminal Id
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Amount
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Payment Date
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Card Pan
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Card Type
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Store
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Account Name
                  </th>
                  <th
                    class=" text-xs text-left pl-6 py-4 leading-6 text-gray-900 font-semibold uppercase"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody
                v-if="tableData && tableData.length"
                class="bg-white w-full"
              >
                <tr
                  v-for="payment in tableData"
                  :key="payment.payment_log_id"
                  class="whitespace-nowrap   even:bg-gray-100  cursor-pointer"
                  @click="
                    modalObject = payment;
                    modal = true;
                  "
                >
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{ payment.payment_reference }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{ bankNameType(payment.payment_method) }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{
                      payment.payment_log_details.terminal_details
                        .terminal_id || "N/A"
                    }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{ payment.amount_paid | formatPrice }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{
                      moment(payment.date_of_payment_by_customer).format(
                        "Do-MMM-YYYY"
                      )
                    }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{
                      payment.payment_log_details.payment_details.card_number ||
                        "N/A"
                    }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{
                      payment.payment_log_details.payment_details.card_type ||
                        "N/A"
                    }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{ payment.store_name }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{
                      payment.payment_log_details.bank_payment_details
                        .account_name
                    }}
                  </td>
                  <td
                    class="  pl-6 text-left text-sm py-4 text-gray-800   font-medium text-opacity-75 uppercase"
                  >
                    {{ payment.payment_status }}
                  </td>
                </tr>
              </tbody>
              <tbody class="bg-white w-full " v-else>
                <tr>
                  <td
                    class=" pl-6 text-sm text-gray-800 py-4  font-medium text-opacity-75 uppercase"
                  >
                    There is no data available.
                  </td>
                  <td
                    v-for="i in 7"
                    :key="i"
                    class="  text-sm text-gray-800 py-4  font-medium text-opacity-75 uppercase"
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <PaymentModal
        style="z-index:99999999999999"
        v-model="modal"
        :modalObject="modalObject"
      />
    </div>
  </div>
</template>

<script>
import PaymentModal from "./PaymentModal.vue";
import moment from "moment";

export default {
  props: ["tableData", "length"],

  data() {
    return {
      moment,
      modal: false,
      modalObject: {},
    };
  },

  components: {
    PaymentModal,
  },

  methods: {
    bankNameType(account) {
      if (account == "bank_transfer") {
        return "Bank Transfer (Dynamic)";
      } else if (account == "fixed_account") {
        return "Bank Transfer (Fixed)";
      } else {
        return account;
      }
    },

    checkScroll(e) {
      const myDiv = e.target;
      const shownPaymentsSofar = this.tableData;

      if (myDiv.offsetHeight + myDiv.scrollTop >= myDiv.scrollHeight) {
        if (shownPaymentsSofar.length === this.length) {
        } else {
          this.$emit("getMoreData");
        }
      }
    },
  },
};
</script>

<style></style>
