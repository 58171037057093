<template>
  <div
    class="
             w-65
              h-24
              px-6
              relative
              small-shadow
              rounded-md
              flex
              items-center
              py-4
              bg-white
            "
  >
    <img src="@/assets/svg/payoutalert.svg" class="w-10 ml-2 h-10" alt="" />
    <div>
      <!-- <div class="tiny ml-6 pr-10">You have pending payouts</div> -->
      <div
        @click="$router.push({ name: 'Payouts' })"
        class="
                text-xs  
                font-semibold 
              
                  cursor-pointer
                  flex
                  items-center
                  ml-6
                  pr-10
                  uppercase
               
                  text-primaryBlue
                  mt-0
                "
      >
        View payout
        <svg
          class="w-2 h-3 ml-2 stroke-current"
          viewBox="0 0 7 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 11.5L6.4 6.1L1 0.700001"
            stroke="#003283"
            stroke-width="1.2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <svg
      class="w-6 h-6 absolute top-0 right-0 -mr-2 -mt-1"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="14" r="13.5" fill="#253B95" fill-opacity="0.33" />
      <circle cx="13.5" cy="14" r="10.5" fill="#253B95" fill-opacity="0.72" />
      <circle cx="13.4546" cy="14" r="6.54541" fill="#253B95" />
    </svg>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
